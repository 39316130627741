<template>
  <modal-card title="User discounts">
    <loading v-if="loading" />
    <template v-else>
      <div v-if="priceTiersArray.length" class="message is-dark">
        <div class="message-body has-padding-75">
          <apart>
            <p>Add discount to this user.</p>
            <button
              class="button is-dark is-rounded is-outlined"
              @click="showAddEditModal(null)"
            >
              Add discount
            </button>
          </apart>
        </div>
      </div>

      <b-table
        :data="priceTiersArray"
        :loading="loading"
        :mobile-cards="true"
        striped
        hoverable
        class="has-vertical-align-middle"
      >
        <template slot="empty">
          <no-results
            :cta="{ label: 'Add discount', class: 'is-success' }"
            icon="user-tag"
            title="No discounts for this user"
            message="This user doesn't have any personal discounts assigned"
            @click="showAddEditModal(null)"
          />
        </template>

        <template slot-scope="props">
          <b-table-column field="description" label="Description">{{
            props.row.description
          }}</b-table-column>

          <b-table-column field="meta.subscriptions" label="Subscriptions">{{
            props.row.meta.subscriptions
          }}</b-table-column>

          <b-table-column field="discount" label="Discount%">
            {{
              props.row.type === "percent"
                ? `${props.row.discount.gbp * -1}%`
                : $formatCurrency(props.row.discount.gbp, "gbp")
            }}
          </b-table-column>

          <b-table-column field label=" " width="120">
            <span
              class="has-cursor-pointer"
              @click="showAddEditModal(props.row._id)"
              >Edit |</span
            >
            <span
              class="has-text-danger has-cursor-pointer"
              @click="deletePriceTier(props.row._id)"
              >Delete</span
            >
          </b-table-column>
        </template>
      </b-table>
    </template>
  </modal-card>
</template>

<script>
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from "@firebase/firestore";
export default {
  name: "ListPriceTiersModal",
  props: {
    userId: { type: String, required: true }
  },
  data() {
    return {
      loading: true,
      priceTiers: {}
    };
  },
  computed: {
    priceTiersArray() {
      return this.$_(this.priceTiers)
        .orderBy(["meta.subscriptions"], ["desc"])
        .toArray()
        .value();
    }
  },
  mounted() {
    this.getPriceTiers();
  },
  methods: {
    getPriceTiers() {
      this.loading = true;
      this.priceTiers = {};

      getDocs(
        query(
          collection(this.$firestore, "priceTiers"),
          where("group", "==", `user-${this.userId}`)
        )
      )
        .then(priceTiers => {
          priceTiers.docs.forEach(doc => {
            this.$set(
              this.priceTiers,
              doc.id,
              this.$_.merge({}, { _id: doc.id }, doc.data())
            );
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showAddEditModal(id) {
      const modal = this.$modal.open({
        component: () => import("@shared/account/_addPriceTierModal"),
        parent: this,
        width: 480,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          priceTierId: id,
          userId: this.userId
        },
        events: {
          sucess: () => {
            modal.close();
            this.getPriceTiers();
          }
        }
      });
    },
    deletePriceTier(priceTierId) {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Delete",
          message: "Are you sure you want to delete this price tier"
        },
        events: {
          confirmed: () => {
            const ref = doc(this.$firestore, "priceTiers", priceTierId);
            deleteDoc(ref).then(async () => {
              if (this.priceTiersArray.length === 1) {
                const userRef = doc(this.$firestore, "users", this.userId);
                await updateDoc(userRef, {
                  priceTierGroup: deleteField()
                });
              }

              this.$nextTick(this.getPriceTiers());
              confirm.close();
            });
          }
        }
      });
    }
  }
};
</script>
